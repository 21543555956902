import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import SeoHelper from '../components/seoHelper';

// styles
const pageStyles = {
  alignItems: 'center',
  color: '#232129',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  margin: '0 auto',
  padding: 0,
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};
const headingStyles = {
  alignItems: 'end',
  height: '200px',
  display: 'flex',
};

const mainStyles = {
  flex: 1,
};

const footerStyles = {
  alignItems: 'center',
  display: 'flex',
  height: '55px',
  textAlign: 'center',
};
const hiddenImage = {
  height: 0,
  left: 0,
  position: 'absolute',
  top: 0,
  visibility: 'hidden',
  width: 0,
};

// markup
const IndexPage = () => {
  return (
    <>
      <SeoHelper title="Airline Bike Park" />
      <div style={pageStyles as React.CSSProperties}>
        <header style={headingStyles}>
          <StaticImage
            height={150}
            layout="constrained"
            src="../images/airline-bike-park-logo.png"
            alt="Airline Bike Park"
          />
        </header>
        <main style={mainStyles}>
          <p
            style={{
              color: '#444',
              fontSize: '25px',
              margin: '20vh 0 0',
            }}
          >
            Website coming soon!
          </p>
        </main>
        <footer style={footerStyles as React.CSSProperties}>
          <p>
            © {new Date().getFullYear()} Tarheel Trailblazers, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </p>
        </footer>
        <div style={hiddenImage as React.CSSProperties}>
          <StaticImage
            layout="constrained"
            src="../images/AIRLINE_Promo-2000x1294.png"
            alt="Airline Bike Park"
          />
          <StaticImage
            layout="constrained"
            src="../images/pp.svg"
            alt="Airline Bike Park"
          />
          <StaticImage
            layout="constrained"
            src="../images/paypal.png"
            alt="Airline Bike Park"
          />
        </div>
      </div>
    </>
  );
};

export default IndexPage;
